<template>
    <BaseCTA
        title="Get access to high quality and concise videos."
        subtitle="Join the MedPeers community"
    >
        <template #cta>
            <BaseButton
                icon-right="arrow-right"
                gtm-join
                @click="modalStore.toggleModal(MODAL_REGISTER);"
            >
                Join MedPeers
            </BaseButton>
        </template>
        <BentoFrame
            class="bento-frame--even-padding"
            dark
        >
            <div>
                <DetailsList
                    :items="[
                        {
                            icon: 'camera-user',
                            title: 'Made by peers',
                            description: 'Learn from experts in your field'
                        },
                        {
                            icon: 'thumbs-up',
                            title: 'Reviewed by peers',
                            description: 'Following professional standards'
                        },
                        {
                            icon: 'mystery',
                            title: 'Easy to find',
                            description: 'No more endless searching the web'
                        },
                        {
                            icon: 'lock',
                            title: 'Closed secure platform',
                            description: 'For healthcare professionals only'
                        },
                    ]"
                />
            </div>
        </BentoFrame>
    </BaseCTA>
</template>

<script setup>
import { useModalStore, MODAL_REGISTER } from '~/store/modal';

const modalStore = useModalStore();
</script>
